<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Update System User 
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>  
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemUserList' }">System User</router-link></li> 
            <li class="breadcrumb-item text-gray-600">Update System User</li> 
          </ul> 
      </div>  
      <div class="d-flex align-items-center py-1">
          <router-link to="/SystemUserList">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
              <span class="ms-2 fs-7"> Return back to List</span>
              </button>
          </router-link> 
          &nbsp;
          <div class="dropdown">
              <button class="btn btn-light-info border border-info dropdown-toggle" type="button" @click="this.isOpenActionsDropdown = !this.isOpenActionsDropdown;">
              <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
              <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
              </svg></span>
                  Actions
              </button>
              <ul class="dropdown-menu" :class="{ 'show': this.isOpenActionsDropdown }"> 
                  <li>
                      <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteUser()">
                          <span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                              <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                              <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                              </svg>
                          </span>
                          Delete
                      </a>
                  </li>
              </ul>
          </div>  
      </div>    
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
      <RequiredField></RequiredField>
      <div class="tab-content" id="myTabContent">
        <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
            <div class="p-10"> 
              <div class="row">                
                  <div class="col-lg-12 mt-0"> 
                    <div class="d-flex flex-row row">
                        <div class="d-flex flex-column flex-row-auto col-lg-12" v-if="form.is_mail_verified !== 1"> 
                          <div class="d-flex flex-column-fluid card card-xxl-stretch">
                            <div class="alert alert-dismissible bg-light-danger border border-danger d-flex flex-column flex-sm-row w-100 p-5 mb-10">
                              <i class="ki-duotone ki-search-list fs-2hx text-primary me-4 mb-5 mb-sm-0"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                              <div class="d-flex flex-column pe-0 pe-sm-10">
                                  <h4 class="fw-semibold">Verify Account!</h4>
                                  <span>A verification link has been sent to the <span class="link-primary fw-bold"> {{form.user_mail}} </span>. The account must be verified by setting a password on the link sent. 
                                    The user will not be able to access the system until the account is verified.
                                  </span>
                                  <br>
                                  <span>Didn’t receive an email? Please check your spam folder or <a href="#" class="link-primary fw-bold" @click="sendVerifyLink()"> Try Again </a></span>
                              </div>
                              <button type="button" class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                                  <i class="ki-duotone ki-cross fs-1 text-primary"><span class="path1"></span><span class="path2"></span></i>
                              </button>
                            </div>
                          </div> 
                        </div>
                        <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                <div class="card-body">
                                    <div class="row">
                                      <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                          Name, Surname
                                        </label>
                                        <input type="text" class="form-control" placeholder="" name="name" v-model="form.name_surname"> 
                                      </div>
                                      <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            Email
                                        </label>
                                        <div class="input-group">
                                          <input type="text" class="form-control" placeholder="" name="name" v-model="form.user_mail" autocomplete="off" disabled>
                                          <span v-if="this.form.is_mail_verified == 1" class="input-group-text bg-light-success text-gray-700 fs-7" id="basic-addon2"> <i class="bi bi-check-circle-fill me-1 text-success fw-bold"></i> Verified</span>
                                        </div>
                                      </div>
                                      <div class="col-lg-6">
                                        <div class="form-check form-switch form-check-custom mt-5">
                                          <input class="form-check-input" type="checkbox" v-model="form.isActive" id="isActive" :disabled="form.is_mail_verified == 0"/>
                                          <label class="form-check-label form-label mt-2" for="isActive">
                                            Is Active?
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-lg-12">
                                          <label class="fs-6 fw-bold form-label mt-3">
                                              Permission Type
                                          </label>
                                          <Select2 v-model="form.permission_type" :options="permissionTypes" :settings="{width: '100%'}" />
                                      </div>
                                      <!-- <div class="col-lg-6">
                                        <div class="form-check form-switch form-check-custom mt-5">
                                          <input class="form-check-input" type="checkbox" v-model="form.isChangePassword" id="flexSwitchDefault"/>
                                          <label class="form-check-label form-label mt-2" for="flexSwitchDefault">
                                              Change Password
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-lg-12" v-if="form.isChangePassword">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            New Password
                                        </label>
                                        <div class="position-relative"> 
                                        <input id="Ps_Password" type="password" class="form-control" placeholder="" name="user_password" v-model="form.user_password" autocomplete="off"
                                          :class="{'border border-success': isPasswordConfirm == true}"> 
                                        <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility()">
                                            <i v-if="isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                            <i v-if="!isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                        </span>
                                        </div>
                                      </div>
                                      <div class="col-lg-12" v-if="form.isChangePassword">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            Confirm Password
                                        </label>
                                        <input type="password" class="form-control" placeholder="" name="confirm_password" v-model="form.confirm_password" autocomplete="off"
                                          :class="{'border border-success': isPasswordConfirm == true,
                                            'border border-danger': isPasswordConfirm == false}" @paste.prevent> 
                                        <small class="text-danger" v-if="isPasswordConfirm == false">
                                          The password and its confirm are not the same
                                        </small>
                                      </div> -->
                                    </div> 
                                </div>
                            </div> 
                        </div>
                        <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                          <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-lg-12">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        Phone
                                    </label>
                                    <div class="input-group">
                                      <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                      <input type="text" class="form-control" name="name" v-model="form.phone"> 
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                  <label class="fs-6 fw-bold form-label mt-3">
                                    Gender
                                  </label>
                                  <Select2 v-model="form.gender" :options="genderList" :settings="{ width: '100%' }"/>
                                </div>
                                <div class="col-lg-6">
                                  <label class="fs-6 fw-bold form-label mt-3">
                                    Date of Birth
                                  </label>
                                  <Datepicker name="date_of_birth" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.date_of_birth" 
                                  format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" :maxDate="new Date()" />
                                </div>
                                <div class="col-lg-12">
                                  <label class="fs-6 fw-bold form-label mt-3">
                                    User Assignment
                                  </label>
                                  <Select2 v-model="form.user_assignment_id" :options="userAssignments" :settings="{width: '100%'}" />
                                </div>
                              </div> 
                            </div>
                          </div> 
                        </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                          <div class="card-body pb-0">
                              <form id="kt_ecommerce_settings_general_form"
                                  class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                  <div class="fv-row mb-7 row">
                                      <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                          Login Method
                                        </label> 
                                        <Select2 v-model="form.login_method" :options="loginMethods" :settings="{width: '100%'}" />
                                      </div>
                                      <div class="col-lg-6" v-if="form.login_method == 2">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                          Authentication Type
                                        </label> 
                                        <Select2 v-model="form.authentication_type" :options="authenticationTypes" :settings="{width: '100%'}" />
                                      </div>
                                      <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                          Validity Type
                                        </label> 
                                        <Select2 v-model="form.validity_type" :options="validityTypes" :settings="{width: '100%'}" />
                                      </div>
                                      <div class="col-lg-6" v-if="form.validity_type == 2">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                          Validity Start Date
                                          <small v-if="form.validity_date_start == null" class="text-danger ms-3"> Please enter a valid date </small>
                                        </label> 
                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_start" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                      </div>
                                      <div class="col-lg-6" v-if="form.validity_type == 2">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                          Validity End Date
                                          <small v-if="form.validity_date_end == null" class="text-danger ms-3"> Please enter a valid date </small>
                                        </label> 
                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_end" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />  
                                      </div>
                                      <div class="col-lg-12">
                                          <label class="fs-6 fw-bold form-label mt-3">
                                              Description
                                          </label>
                                          <textarea type="text" rows="2" class="form-control" placeholder="" name="name" v-model="form.user_description" /> 
                                      </div>
                                      <div class="col-lg-12" v-if="form.login_method == 2 && form.authentication_type == 2">
                                         <label class="fs-6 fw-bold form-label mt-3">Google Auth Setup</label> 
                                         <br> 
                                          <div class="alert alert-primary d-flex align-items-center p-5"> 
                                              <div class="d-flex flex-column"> 
                                                  <h4 class="mb-1 text-dark"></h4> 
                                                  <span>
                                                    <p>
                                                        Download Google's two-factor authenticator: <br>
                                                        Google Authenticator for
                                                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en" target="_blank">Android</a> and
                                                        <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank">iOS</a>.
                                                    </p>
                                                    <p>Click Generate QR Code button below. Scan the QR Code on app. </p>
                                                    <p>
                                                        Once you have scanned the QR code, your two factor authentication app will register you to two-factor authentication system. 
                                                    </p>
                                                  </span>
                                              </div>
                                          </div>
                                         <button type="button" class="btn btn-primary btn-sm" @click="onGenerateQRCode()">
                                            Generate QR Code
                                         </button>
                                         <div v-if="qrImage" class="mt-4">
                                            <img v-bind:src="qrImage" />
                                        </div> 
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12 mt-0"> 
                    <div class="d-grid gap-2"> 
                        <button type="button" class="btn btn-lg btn-primary " @click="this.saveData()" data-kt-indicator="on" :disabled="isPasswordConfirm == false">
                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                            </svg>
                            </span>
                              <strong v-if="!this.isProgressing"> Update System User </strong> 
                                <span class="indicator-progress" v-if="this.isProgressing">
                                    Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                        </button>
                    </div> 
                  </div>
              </div> 
            </div>
          </div>
      </div>
    </div> 
  </div> 
</template>

<script>
// Component Alias: `USP001`
import axios from 'axios' 
  
import Swal from 'sweetalert2';

 
export default {
  name: "SystemUserUpdate",
  data() {
    return {
      isOpenActionsDropdown: false,
      lists: [],
      isProgressing: false,
      accountTypes: [{ id: 1, text: "System User" }, { id: 2, text: "Controller User" }, { id: 3, text: "Company User" }],
      permissionTypes: [{ id: 1, text: "Admin" }, { id: 2, text: "Client" }],
      loginMethods: [{ id: 1, text: "Standard" }, { id: 2, text: "2FA" }],
      authenticationTypes: [{ id: 1, text: "SMS" }, { id: 2, text: "Google 2FA" }],
      validityTypes: [{ id: 1, text: "Always" }, { id: 2, text: "Date Range" }],
      userAssignments: [{ id: '-1', text: 'Select an option' }],
      isHide: true,
      form: { 
        id: null,
        account_type: 1,
        permission_type: 1,
        company_account_id: null,
        controller_account_id: null,
        user_assignment_id: '-1',
        system_account_id: null,
        user_mail: null,
        user_password: null,
        name_surname: null,
        phone: null,
        user_description: null,
        login_method : 1,
        authentication_type: 1,
        validity_type: 1,
        validity_date_start: new Date(),
        validity_date_end: new Date(),
        password_validity: -1,
        is_active: 1,
        isActive: true,
        isChangePassword: false,
        confirm_password: null,
        is_mail_verified: 1,
        phone_country_code: null,
        gender: '-1',
        date_of_birth: null
      },
      qrImage: null,
      authCode: null,
      phoneMaskList: [],
			genderList: [
				{id: '-1', text: 'Select an option'},
				{id: 'M', text: 'Male'},
				{id: 'F', text: 'Female'},
				{id: 'O', text: 'Other'},
			],
    };
  },
  computed: {
    isPasswordConfirm(){
      if(this.form.isChangePassword) {
        if(this.form.user_password !== null) {
          if(this.form.user_password == this.form.confirm_password) return true;
          else return false;
        }
        else return null;
      }
      else { return null;}
    }
  },
  methods: {
    getData() { 
      this.lists = []; 
      this.$setSessionStorage('refreshList', true);
      const parameters = {
        id: this.$getSessionStorage("record_id"),
        session_id: this.$getUser().session_id,
        get_access_status: 0,
        list_type: 18 // User Assignment List: 18
      };  
      
      const requestOne =  axios.post('/api/Users/GetUser', parameters, {'Content-Type': 'application/json'});
      const requestTwo =  axios.post('/api/Lists/GetLists', parameters, {'Content-Type': 'application/json'}); 
      const requestThree =  axios.post('/api/Lists/GetPhoneMasks', parameters, {'Content-Type': 'application/json'}); 
      
      axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
            this.form = { 
                id: responses[0].data.id,
                account_type: responses[0].data.account_type,
                permission_type: responses[0].data.permission_type,
                company_account_id: this.$unlock(responses[0].data.company_account_id),
                controller_account_id: this.$unlock(responses[0].data.controller_account_id),
                system_account_id: this.$unlock(responses[0].data.system_account_id),
                user_mail: this.$unlock(responses[0].data.user_mail),
                phone_country_code: this.$unlock(responses[0].data.phone_country_code),
                name_surname: this.$unlock(responses[0].data.name_surname),
                phone: this.$unlock(responses[0].data.phone),
                user_password: null,
                confirm_password: null,
                isChangePassword: false,
                user_description: responses[0].data.user_description,
                login_method : responses[0].data.login_method,
                authentication_type: responses[0].data.authentication_type,
                validity_type: responses[0].data.validity_type,
                validity_date_start: responses[0].data.validity_date_start,
                validity_date_end: responses[0].data.validity_date_end,
                password_validity: responses[0].data.password_validity,
                is_active: responses[0].data.is_active, 
                user_assignment_id: responses[0].data.user_assignment_id == null || responses[0].data.user_assignment_id == '' ? '-1' : responses[0].data.user_assignment_id,
                isActive: responses[0].data.is_active == 1 ? true : false,
                is_mail_verified: responses[0].data.is_mail_verified,
                date_of_birth: responses[0].data.date_of_birth,
                gender: responses[0].data.gender == null || responses[0].data.gender == '' ? '-1' : responses[0].data.gender,
            };
            this.userAssignments = responses[1].data; 
            this.userAssignments.unshift({ id: '-1', text:  'Select an option'}); 
            this.phoneMaskList = responses[2].data;
            if(this.form.phone_country_code == null || this.form.phone_country_code == '' || this.form.phone_country_code == undefined) {
              this.phoneMaskList.forEach(element => {
                if(element.code == this.$getUser().system_account_country_code) {
                  this.form.phone_country_code = element.id;
                }
              });
            }
      }))
      .catch(errors => { 
          console.error(errors); 
      });
    },
    togglePasswordVisibility() {
      this.isHide = !this.isHide;
      var x = document.getElementById("Ps_Password");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this; 
      params.licence_id = this.$getUser().system_account_id;
      params.controller_account_id = this.$getUser().controller_account_id; 
      params.system_account_id = this.$getUser().system_account_id;
      if(params.isActive){
        params.is_active = 1;
      }
      else{
        params.is_active = 0;
      }
      params.session_id = this.$getUser().session_id;
      params.company_account_id = null;
      if (params.user_assignment_id == '-1') {params.user_assignment_id = null;}
      if (params.gender == '-1') {params.gender = null;}
      params.date_of_birth = params.date_of_birth !== null ? moment(params.date_of_birth).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      axios.post('/api/Users/UpdateUser', params, {'Content-Type': 'application/json'})
        .then((response) => { 
           if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success'); 
                this.$setSessionStorage("refreshList", true);
                this.getData();    
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
      },
      deleteUser() { 
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {
            const queryParameter = {
                id: this.form.id,
                session_id: this.$getUser().session_id,
            };
            axios.post('/api/Users/DeleteUser', queryParameter, {'Content-Type': 'application/json'})
            .then((response) => { 
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                new Swal( { icon: 'error', title: 'Deleted...', text: 'Data deleted..!' });
                this.$setSessionStorage("refreshList", true);
                this.$router.push({ name: "SystemUserList" });
              }
            })
            .catch(function () { 
              new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be deleted..Please refresh the page and try again..!' });   
            }); 
          }
        })
    },
    onGenerateQRCode(){
      const parameters = {
        user_id: this.$getUser().id,
        user_mail: this.form.user_mail
      }; 
      axios.post('/api/Users/GetAuthenticationCode', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.qrImage = response.data;
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    sendVerifyLink() {
      const parameters = {
        session_id: this.$getUser().session_id,
        user_mail: this.form.user_mail,
        account_type: 1
      };  
      const that = this;
      axios.post('/api/Users/SendVerifyLink', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
         if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Verification link has been successfully sent.", 'success');   
         }
      })
      .catch(function () {
         that.$swal("Failed", "An error occurred while sending the email. You can wait for a while to resend or check the mail server settings.", 'error');
      });
    },
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>