<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Edit Template
                </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
                    </li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'DataImportTemplates' }">Data Import Templates</router-link></li>
                    <li class="breadcrumb-item text-gray-600">Edit Template</li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <router-link to="/DataImportTemplates">
                    <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                        <span class="ms-2 fs-7"> Return back to List</span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10">
                        <div class="row">
                            <div class="col-lg-12 mt-0">
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Category
                                                        </label>
                                                        <Select2 v-model="form.category_id" :options="categoryList" :settings="{ width: '100%' }"/>
                                                    </div>
                                                    <!-- <div class="col-lg-12" v-if="form.category_list">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Source
                                                        </label>
                                                        <Select2 v-model="form.source" :options="sourceList" :settings="{ width: '100%' }" />
                                                    </div> -->
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Name
                                                        </label>
                                                        <input type="text" class="form-control" placeholder="" name="name" v-model="form.name">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            File Type
                                                        </label>
                                                        <Select2 v-model="form.file_type_id" :options="fileSourceTypeList" :settings="{ width: '100%' }" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="fv-row">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                        Description
                                                        </label>
                                                        <textarea class="form-control" name="description" v-model="form.description"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        Clone Template
                                                    </label>
                                                    <Select2 v-model="form.clone_template" :options="cloneTemplateList" :settings="{ width: '100%' }"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                            <div class="col-lg-12 text-start">
                                <button class="btn btn-primary w-100 mt-4" @click="this.onUpdateTemplate()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                    </svg>
                                Update
                                </button>
                            </div>
                        </div>
                        
                        <!-- FILE UPLOAD: EXCEL -->    
                        <div class="col-lg-12 mt-7" v-if="this.form.file_type_id == 1 || this.form.file_type_id == 3 || this.form.file_type_id == 4"> 
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="kt_tab_pane_excel" role="tabpanel">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        Upload Excel File
                                    </label>
                                    <input type="file" id="input" class="form-control form-control-lg input-lg" @change="onFileChange" ref="excelFile"/>
                                </div>                             
                            </div> 
                            <div class="table-responsive mt-7" v-if="this.sheetNames.length > 0">
                                <table class="table table-rounded table-striped border gy-2 gs-2 table-hover mb-0">
                                    <thead>
                                        <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-300">
                                            <th><strong>Worksheet Name</strong></th>
                                            <th>Description</th>
                                            <th class="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in this.sheetNames" v-bind:key="item">
                                            <td class="align-middle fw-bold">
                                                <strong> {{ item.name }} </strong>  
                                            </td>
                                            <td class="align-middle">
                                                <input type="text" class="form-control form-control-sm" v-model="item.description" :disabled="item.is_disabled">
                                            </td> 
                                            <td class="text-center align-middle">
                                               <button type="button" class="btn btn-sm btn-primary p-1 px-3" @click="this.onAddWorksheet(item)" :disabled="item.is_disabled">
                                                   <i class="fa fa-plus"></i> Add
                                               </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-responsive min-h-300px mt-4">
                                <DxDataGrid 
                                    :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="addedWorksheets" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    data-row-template="dataRowTemplate" 
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" >
                            
                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      

                                    <DxSearchPanel :visible="true"/> 
                                    <DxColumn data-field="column_order" caption="Order" alignment="left" :width="150"/> 
                                    <DxColumn data-field="name" caption="Name" header-cell-template="name-header"/> 
                                    <DxColumn data-field="virtual_name" caption="Virtual Name" header-cell-template="virtual-header"/> 
                                    <DxColumn data-field="description" caption="Description" :width="300" header-cell-template="description-header"/>
                                    <DxColumn caption="Actions" header-cell-template="actions-header" width="150" alignment="center"/>

                                    <template #name-header>
                                        <div>Worksheet Name</div>
                                    </template>
                                    <template #virtual-header>
                                        <div>Virtual Name</div>
                                    </template>
                                    <template #description-header>
                                        <div>Description</div>
                                    </template>
                                    <template #actions-header>
                                        <div>Actions</div>
                                    </template>
                                    <template #dataRowTemplate="{ data: data }">
                                        <td class="text-gray-900 align-middle fw-bolder">
                                            <div class="position-relative d-flex align-items-center">
                                                <button type="button" class="btn btn-xs btn-secondary" @click="onUpdateOrder(data.data, 'minus')">
                                                    <i class="bi bi-dash fs-5"></i>
                                                </button>
                                                <CurrencyInput type="text" class="border-0 text-center px-0 fw-bold w-50px" 
                                                    :precision="0" v-model="data.data.column_order" @focus="$event.target.select()" :min="0" @blur="onUpdateOrder(data.data)"/>
                                                <button type="button" class="btn btn-xs btn-secondary" @click="onUpdateOrder(data.data, 'plus')">
                                                    <i class="bi bi-plus fs-5"></i>
                                                </button>
                                            </div>
                                        </td> 
                                        <td class="text-gray-900 align-middle fw-bolder">
                                            {{ data.data.name }}
                                        </td> 
                                        <td class="text-gray-900 align-middle fw-bolder">
                                            {{ data.data.virtual_name }}
                                        </td> 
                                        <td class="text-gray-900 align-middle fw-bolder">
                                            {{ data.data.description }}
                                        </td> 
                                        <td class="text-gray-900 align-middle text-center">
                                            <a class="btn btn-danger btn-sm p-1 px-3 cursor-pointer" 
                                                @click="this.onRemoveWorksheet(data.data, this.rowIndex)">
                                                <i class="fa fa fa-times"></i> 
                                                Remove
                                            </a>
                                        </td>  
                                    </template> 
                                </DxDataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios' 


import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid';
 
export default {
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxColumn, DxSearchPanel
  },
  data() {
    return {
        sourceList: [],
        cloneTemplateList: [{ id: '-1', text: 'Select an option' },],
        isHide: true,
        form: { 
            category_id: 0,
            source: 1,
            clone_template: 0,
            description: null,
            file_type_id: 0
        },
        categoryList: [
            { id: 0, text: 'Select an option'},
            { id: 1, text: 'Accounts'},
            { id: 2, text: 'Bank Statement'},
            { id: 5, text: 'Collection'},
            { id: 3, text: 'Insurance Policy'},
            { id: 4, text: 'Invoice'},
        ],
        sqlFields: [ 
            'id',
            'company_id',
            'account_type',
            'account_id',
            'name',
            'qr_code',
            'notes',
            'created_by',
            'created_at',
            'last_transaction_at',
            'branch_id',
            'expense_center_id',
            'product_code',
            'is_show_invoice'
        ],
        dataTypes: [ 'None', 'Boolean' ,'Text' ,'Numeric' ,'DateTime' ,'Unknown' ],
        sheetNames: [],
        excelFields: [],
        excelContent: [],
        excelColumns: [],
        customExcelFields: [],
        fileSourceTypeList: [
            { id: 1, text: 'Excel File (xlsx)' },
            { id: 2, text: 'XML File' },
            { id: 4, text: 'Excel File (xls)' },
            { id: 3, text: 'CSV File' },
        ],
        targetDatasetList: [
            { id: 0, text: 'SELECT DATASET' },
            { id: 1, text: 'Accounts' },
            { id: 2, text: 'Invoices' },
            { id: 3, text: 'Transactions' },
            { id: 4, text: 'Policies' }
        ],
        customRelationalFields: [],
        addedWorksheets: []
    };
  },
  methods: {
    onGetTemplate(){
        const params = {
            session_id: this.$getUser().session_id,
            id: this.$getSessionStorage('record_id')
        };
        axios.post('api/Office/GetDataImportTemplateById', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){      
                this.form = response.data;

                const params = {
                    session_id: this.$getUser().session_id,
                    template_id: this.$getSessionStorage('record_id')
                };
                axios.post('api/Office/GetWorksheetParameters', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                        this.addedWorksheets = response.data;
                    }
                });
            }
        })
    },
    onFileChange(event) {
      const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('record_id',  this.$getSessionStorage('record_id'));
        formData.append('file_type',  this.form.file_type_id);
        this.fileName = event.target.files[0].name;
        fetch('/api/Office/GetWorksheets', {
            method: 'POST',
            body: formData,
            headers: {}
        }).then(response => response.json())
            .then(data => { 
                data.forEach((item) => {
                    this.sheetNames.push({
                        name: item,
                        description: null
                    });
                });
            });
    },
    GetExcelData(sheetName){
      const params = {
        worksheet_name: sheetName,
        workbook_name: this.fileName,
        session_id: this.$getUser().session_id,
        file_type_id: this.form.file_type_id
      };    
      axios.post('/api/Office/GetExcelData', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.excelContent = response.data.content;
          this.excelColumns = response.data.columns;
          this.excelFields = response.data.fields;
        })
        .catch(function (error) {
            console.log(error);
        }); 
    },
    onInsertNewRow(){
        this.customExcelFields.push(
            {
                id: Math.random()
            }
        );
    },
    getXMLKeys(json_object, ret_array = []){
        for (var json_key in json_object) {
            if (typeof(json_object[json_key]) === 'object' && !Array.isArray(json_object[json_key])) {
                ret_array.push(json_key);
                this.getXMLKeys(json_object[json_key], ret_array);
            } else if (Array.isArray(json_object[json_key])) {
                ret_array.push(json_key);
                var first_element = json_object[json_key][0];
                if (typeof(first_element) === 'object') {
                    this.getXMLKeys(first_element, ret_array);
                }
            } else {
                ret_array.push(json_key);
            }
        }
        return ret_array
    },
    onXMLFileChange(event){
        const formData = new FormData();
            formData.append('file', event.target.files[0]);
            this.fileName = event.target.files[0].name;
            fetch('/api/Office/GetXMLContent', {
                method: 'POST',
                body: formData,
                headers: {}
            }).then(response => response.json())
                .then(data => {
                // JSON Parse
                var allNodes = [];
                this.getXMLKeys(data, allNodes);
                });
    },
    onAddWorksheet(item){
        var virtualName = "";
        //Eğer bu isimde bir worksheet varsa _{0} ekleyerek devam et
        var i = 0;
        this.addedWorksheets.forEach(element => {
            if(element.name.includes(item.name)){
                i++;
            }
        });
        if(i > 0){
            virtualName = item.name + "_" + i;
        }
        else{
            virtualName = item.name;
        }

        const params = {
            session_id: this.$getUser().session_id,
            template_id: this.$getSessionStorage('record_id'),
            name: item.name,
            virtual_name: virtualName,
            description: item.description
        };
        axios.post('api/Office/AddWorksheetParameter', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){      
                this.onGetWorksheetParametersFromDB();
            }
        });
    },
    onRemoveWorksheet(item){
        const params = {
            session_id: this.$getUser().session_id,
            id: item.id
        };
        axios.post('api/Office/RemoveWorksheetParameter', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.onGetWorksheetParametersFromDB();
            }
        }); 
    },
    onGetWorksheetParametersFromDB(){
        const params = {
            session_id: this.$getUser().session_id,
            template_id: this.$getSessionStorage('record_id')
        };
        axios.post('api/Office/GetWorksheetParameters', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.addedWorksheets = response.data;
            }
        });  
    },
    onUpdateOrder(item, operator) {
        let columnOrder = item.column_order;
        if(operator == 'plus') columnOrder++;
        if(operator == 'minus') columnOrder--;
        const parameters = {
          session_id: this.$getUser().session_id,
          column_order: columnOrder,
          template_id: item.template_id,
          id: item.id
        };  
        axios.post('/api/Office/UpdateWorksheetOrder', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.addedWorksheets = response.data;
           }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onUpdateTemplate(){
        const params = {
            id: this.$getSessionStorage('record_id'),
            session_id: this.$getUser().session_id,
            name: this.form.name,
            category_id: this.form.category_id,
            description: this.form.description,
            file_type_id: this.form.file_type_id
        };
        axios.post('api/Office/UpdateDataImportTemplate', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){      
                this.$swal("Success", "Data Import Template saved succesfully..!", 'success');
                this.onGetTemplate();
            }
        })
    }
  },
  created(){
    this.onGetTemplate();
  }
}; 
</script>
