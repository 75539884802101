<template>
  <div>   
    <div class="content flex-row-fluid mt-4 pb-0" id="kt_content" :class="{'overlay overlay-block': this.isCompanyPlanSaving  == true}">
        <div class="card">
          <form @submit.prevent="submit"> 
            <div class="card-body p-5">  

              <div class="alert alert-primary d-flex align-items-center p-5 mb-10">  
                <span class="svg-icon svg-icon-2hx svg-icon-primary me-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M5.78001 21.115L3.28001 21.949C3.10897 22.0059 2.92548 22.0141 2.75004 21.9727C2.57461 21.9312 2.41416 21.8418 2.28669 21.7144C2.15923 21.5869 2.06975 21.4264 2.0283 21.251C1.98685 21.0755 1.99507 20.892 2.05201 20.7209L2.886 18.2209L7.22801 13.879L10.128 16.774L5.78001 21.115Z" fill="currentColor"/>
                <path d="M21.7 8.08899L15.911 2.30005C15.8161 2.2049 15.7033 2.12939 15.5792 2.07788C15.455 2.02637 15.3219 1.99988 15.1875 1.99988C15.0531 1.99988 14.92 2.02637 14.7958 2.07788C14.6717 2.12939 14.5589 2.2049 14.464 2.30005L13.74 3.02295C13.548 3.21498 13.4402 3.4754 13.4402 3.74695C13.4402 4.01849 13.548 4.27892 13.74 4.47095L14.464 5.19397L11.303 8.35498C10.1615 7.80702 8.87825 7.62639 7.62985 7.83789C6.38145 8.04939 5.2293 8.64265 4.332 9.53601C4.14026 9.72817 4.03256 9.98855 4.03256 10.26C4.03256 10.5315 4.14026 10.7918 4.332 10.984L13.016 19.667C13.208 19.859 13.4684 19.9668 13.74 19.9668C14.0115 19.9668 14.272 19.859 14.464 19.667C15.3575 18.77 15.9509 17.618 16.1624 16.3698C16.374 15.1215 16.1932 13.8383 15.645 12.697L18.806 9.53601L19.529 10.26C19.721 10.452 19.9814 10.5598 20.253 10.5598C20.5245 10.5598 20.785 10.452 20.977 10.26L21.7 9.53601C21.7952 9.44108 21.8706 9.32825 21.9221 9.2041C21.9737 9.07995 22.0002 8.94691 22.0002 8.8125C22.0002 8.67809 21.9737 8.54505 21.9221 8.4209C21.8706 8.29675 21.7952 8.18392 21.7 8.08899Z" fill="currentColor"/>
                </svg></span> 
                  <div class="d-flex flex-column"> 
                    <h4 class="mb-1 text-primary">You are currently working on the <span class="mb-1 text-dark"><strong> {{ activePeriodInfo.period_year_short }} </strong> </span> accounting period.</h4>
                    <span>Plan will be saved under this period.</span>
                  </div>
              </div>


             <div class="fv-row mb-7 fv-plugins-icon-container">
                    <label class="fs-6 fw-bold form-label mb-2 required">
                      Level
                    </label>
                    <div class="input-group mb-5">
                        <select class="form-control form-control-lg" v-model="form.levelSelection" @change="onChangeLevel()">
                          <option v-for="item in levelList" :value="item" :key="item.code">
                            <strong> {{ item.name }} </strong>
                          </option>
                        </select>
                        <span class="input-group-text" v-if="this.isLoadingPlans && form.levelSelection.code != 1">
                          Getting Plans... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                  </div>
                </div>    
                <!-- LEVEL 2 TO LEVEL 1 --> 
                <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 2">
                    <label class="fs-6 fw-bold form-label mb-2">Level 1 Plan Selection</label>
                    <div class="input-group mb-5">
                     <select class="form-control" v-model="form.level1Selection" @change="onChangeSelectablePlan()">
                       <option v-for="item in levelPlansList" :value="item" :key="item.code">
                        {{ item.final_code }} - {{ item.name }}
                       </option>
                     </select>  
                      <span class="input-group-text">
                         <small> Level 1 Plans Only </small>
                      </span>
                    </div>
                </div>
                <!-- LEVEL 2 TO LEVEL 2 -->
                <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 22">
                    <label class="fs-6 fw-bold form-label mb-2">Level 2 Plan Selection</label>
                    <div class="input-group mb-5">
                     <select class="form-control" v-model="form.level2Selection" @change="onChangeSelectablePlan()">
                       <option v-for="item in onlyLevel2PlansList" :value="item" :key="item.code">
                         {{ item.final_code }} - {{ item.name }}
                       </option>
                      </select>
                       <span class="input-group-text">
                         <small> Level 2 Plans Only </small>
                      </span>
                    </div>
                </div>    

                <!-- LEVEL 3 TO LEVEL 2 --> 
                <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 3">
                    <label class="fs-6 fw-bold form-label mb-2">Level 2 Plan Selection</label>
                    <div class="input-group mb-5">
                    <select class="form-control" v-model="form.level2Selection" @change="onChangeSelectablePlan()">
                       <option v-for="item in levelPlansList" :value="item" :key="item.code">
                        {{ item.final_code }} - {{ item.name }}
                       </option>
                      </select>
                       <span class="input-group-text">
                         <small> Level 2 and 2.2 Plans </small>
                      </span>
                    </div>
                </div>     


                <!-- CODE INPUT --> 
                <!-- LEVEL 1 CODE -->
                <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 1 && this.isReady">
                  <label class="fs-6 fw-bold form-label mb-2 required">
                    Code
                  </label>
                  <input type="text" class="form-control" v-model="form.code" :maxlength="planSettingsData.level_1">
                </div>   
                <!-- LEVEL 2 CODE -->
                <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 2 && this.isReady"> 
                    <label class="fs-6 fw-bold form-label mb-2 required">
                      Code
                    </label>
                    <div class="input-group mb-5"> 
                        <span class="input-group-text" v-for="finalCodePart in this.form.level1Selection.final_code_array" :key="finalCodePart">{{ finalCodePart }}</span>
                        <input type="text" class="form-control" v-model="form.code" :maxlength="planSettingsData.level_2">
                    </div> 
                </div>  
                 <!-- LEVEL 2.2 CODE -->
                <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 22 && this.isReady"> 
                    <label class="fs-6 fw-bold form-label mb-2 required">
                      Code
                    </label>
                    <div class="input-group mb-5"> 
                        <span class="input-group-text" v-for="finalCodePart in this.form.level2Selection.final_code_array" :key="finalCodePart">{{ finalCodePart }}</span>
                        <input type="text" class="form-control" v-model="form.code" :maxlength="planSettingsData.level_2">
                    </div>
                </div>   
                <!-- LEVEL 3 CODE -->
                <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 3 && this.isReady">
                  <label class="fs-6 fw-bold form-label mb-2 required">
                    Code
                  </label>
                  <div class="input-group mb-5" v-if="form.level2Selection !== null">
                    <span class="input-group-text" v-for="finalCodePart in form.level2Selection.final_code_array" :key="finalCodePart">{{ finalCodePart }}</span>
                    <input type="text" class="form-control" v-model="form.code" :maxlength="planSettingsData.level_3"> 
                  </div>
                </div>    

                <div class="fv-row mb-7 fv-plugins-icon-container">
                    <label class="fs-6 fw-bold form-label mb-2 required">
                      Name
                    </label>
                    <input type="text" class="form-control" placeholder="" name="name" v-model="form.name"> 
                </div>   

                <div class="separator separator-dashed my-6"></div>
               
               <!-- <div class="row fv-row mb-7 fv-plugins-icon-container">
                  <div class="col-lg-6">
                    <label class="fs-6 fw-bold form-label mb-2">Record Type <small>Kayıt Şekli</small></label>
                     <select class="form-control" v-model="form.recordTypeSelection">
                       <option v-for="item in recordTypeList" :value="item" :key="item.code">
                        {{ item.name }}
                       </option>
                      </select>
                  </div>
                </div> -->
                <div class="row fv-row mb-7 fv-plugins-icon-container"> 
                  <div class="col-lg-6">
                     <label class="fs-6 fw-bold form-label mb-2">Balance Amount Type</label>
                     <!-- <small>Bakiye Şekli</small> -->
                    <select class="form-control" v-model="form.balanceTypeSelection">
                       <option v-for="item in balanceTypeList" :value="item" :key="item.code">
                        {{ item.name }}
                       </option>
                    </select>
                  </div>
                  <!-- <div class="col-lg-6">
                    <label class="fs-6 fw-bold form-label mb-2">Profit and Loss Account </label>
                    <select class="form-control" v-model="form.incomeItemSelection">
                        <option v-for="item in incomeItemList" :value="item" :key="item.code">
                        {{ item.name }}
                       </option>
                    </select>
                  </div> -->
                  <!-- <div class="col-lg-6">
                    <label class="fs-6 fw-bold form-label mb-2">Balance Item <small>Bilanço Kalemi</small></label>
                    <select class="form-control" v-model="form.balanceItemSelection">
                       <option v-for="item in balanceItemList" :value="item" :key="item.code">
                        {{ item.name }}
                       </option>
                    </select>
                  </div> -->
                </div> 

              <div v-if="form.levelSelection.code == 3">
                <div class="separator separator-dashed my-6"></div>

                <div class="fv-row mb-7 fv-plugins-icon-container">
                  <h5> Accounting Defaults</h5>
                </div>
                
                <div class="fv-row mb-7 fv-plugins-icon-container row">
                  <div class="col-lg-4 mb-7">
                      <label class="fs-6 fw-bold form-label mb-2 required">
                        Plan Type
                      </label>
                      <select class="form-control" v-model="form.planTypeSelection">
                        <option v-for="item in planTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>  
                  <div class="col-lg-4 mb-7">
                      <label class="fs-6 fw-bold form-label mb-2 required">
                        Account Type
                      </label>
                      <select class="form-control" v-model="form.accountTypeSelection" @change="this.getListData()">
                        <option v-for="item in accountTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div> 
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 3">
                      <label class="fs-6 fw-bold form-label mb-2">Cash Type</label>
                      <select class="form-control" v-model="form.cashTypeSelection" @change="this.getCompanyAccounts()">
                        <option v-for="item in cashTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>                   
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 4">
                      <label class="fs-6 fw-bold form-label mb-2">Bank Account Type</label>
                      <select class="form-control" v-model="form.bankAccountTypeSelection" @change="this.getCompanyAccounts()">
                        <option v-for="item in bankAccountTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div> 
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 3 || form.accountTypeSelection.code == 4">
                      <label class="fs-6 fw-bold form-label mb-2">Currency</label>
                      <select class="form-control" v-model="form.currencySelection">
                        <option v-for="item in currencyList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div> 
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 1">
                      <label class="fs-6 fw-bold form-label mb-2">Customer Type</label>
                      <select class="form-control" v-model="form.customerTypeSelection" @change="this.getCompanyAccounts()">
                        <option v-for="item in customerTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div> 
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 2">
                      <label class="fs-6 fw-bold form-label mb-2">Supplier Type</label>
                      <select class="form-control" v-model="form.supplierTypeSelection" @change="this.getCompanyAccounts()">
                        <option v-for="item in supplierTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div> 
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 5">
                      <label class="fs-6 fw-bold form-label mb-2">Personnel Position Type</label>
                      <select class="form-control" v-model="form.personnelPositionTypeSelection" @change="this.getCompanyAccounts()">
                        <option v-for="item in personnelPositionTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 6">
                      <label class="fs-6 fw-bold form-label mb-2">Expense Group</label>
                      <select class="form-control" v-model="form.expenseGroupSelection" @change="this.getCompanyAccounts()">
                        <option v-for="item in expenseGroupList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 7 || form.accountTypeSelection.code == 9 || form.accountTypeSelection.code == 16">
                      <label class="fs-6 fw-bold form-label mb-2">Stock Group</label>
                      <select class="form-control" v-model="form.stockGroupSelection" @change="this.getCompanyAccounts()">
                        <option v-for="item in stockGroupList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 9 || form.accountTypeSelection.code == 16">
                      <label class="fs-6 fw-bold form-label mb-2">Sales Type</label>
                      <select class="form-control" v-model="form.salesTypeSelection">
                        <option v-for="item in salesTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 8 || form.accountTypeSelection.code == 10">
                      <label class="fs-6 fw-bold form-label mb-2">Asset Type</label>
                      <select class="form-control" v-model="form.assetTypeSelection" @change="this.getCompanyAccounts()">
                        <option v-for="item in assetTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 11">
                      <label class="fs-6 fw-bold form-label mb-2">VAT Type</label>
                      <select class="form-control" v-model="form.vatTypeSelection">
                        <option v-for="item in vatTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 12">
                      <label class="fs-6 fw-bold form-label mb-2">Cheque Type</label>
                      <select class="form-control" v-model="form.chequeTypeSelection">
                        <option v-for="item in chequeTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 12 && this.form.chequeTypeSelection.code == 1">
                      <label class="fs-6 fw-bold form-label mb-2">Cheque Operation Type</label>
                      <select class="form-control" v-model="form.chequeOperationTypeSelection">
                        <option v-for="item in chequeOperationTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 11">
                      <label class="fs-6 fw-bold form-label mb-2">VAT Rate</label>
                       <CurrencyInput type="text" step="any" class="form-control text-end" name="vat_rate" v-model="form.vat_rate" :precision="5" @focus="$event.target.select()"/>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 15">
                      <label class="fs-6 fw-bold form-label mb-2">Other Account Group</label>
                      <select class="form-control" v-model="form.otherAccountGroupSelection" @change="this.getCompanyAccounts()">
                        <option v-for="item in otherAccountGroupList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div> 
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 15 || form.accountTypeSelection.code == 6">
                      <label class="fs-6 fw-bold form-label mb-2">Policy Account Type</label>
                      <select class="form-control" v-model="form.policyAccountTypeSelection">
                        <option v-for="item in policyAccountTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div> 
                  <div class="col-lg-4 mb-7" v-if="checkIfAccountView">
                      <label class="fs-6 fw-bold form-label mb-2">Account</label>
                      <Select2 v-model="form.account_id" :options="this.companyAccountsList" :settings="{ width: '100%', dropdownParent: '#addAccountingPlan .modal-content' }"/>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 17">
                      <label class="fs-6 fw-bold form-label mb-2">Transfer Account Type</label>
                      <select class="form-control" v-model="form.transferAccountTypeSelection">
                        <option v-for="item in transferAccountTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 18">
                      <label class="fs-6 fw-bold form-label mb-2">VAT Calculated Type</label>
                      <select class="form-control" v-model="form.vatCalculatedTypeSelection">
                        <option v-for="item in vatCalculatedTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                </div>           
              </div>

              <div v-if="form.levelSelection.code !== null">
                <div class="separator separator-dashed my-6"></div>

                <div class="fv-row mb-7 fv-plugins-icon-container">
                  <h5> Account Transfer Parameters</h5>
                </div>
                <div class="fv-row mb-7 fv-plugins-icon-container row">
                  <div class="col-lg-6 mb-7" v-if="form.levelSelection.code == 1 || form.levelSelection.code == 3">
                      <label class="fs-6 fw-bold form-label mb-3 required">
                        Is Opening Account?
                      </label>
                      <select class="form-control" v-model="form.isOpeningAccountSelection">
                        <option v-for="item in accountTransferParameterList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-6 mb-7" v-if="form.levelSelection.code == 1 || form.levelSelection.code == 3">
                      <label class="fs-6 fw-bold form-label mb-3 required">
                        Is Closing Account?
                      </label>
                      <select class="form-control" v-model="form.isClosingAccountSelection">
                        <option v-for="item in accountTransferParameterList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-6">
                      <label class="fs-6 fw-bold form-label mb-2 required">
                        Is Profit & Loss Account?
                      </label>
                      <select class="form-control" v-model="form.isProfitLossSelection">
                        <option v-for="item in accountTransferParameterList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                  <div class="col-lg-6" v-if="(form.levelSelection.code == 1 || form.levelSelection.code == 3) && form.isProfitLossSelection.code == 2">
                      <label class="fs-6 fw-bold form-label mb-2 required">
                        Profit & Loss Transaction Type
                      </label>
                      <select class="form-control" v-model="form.profitLossTransactionTypeSelection">
                        <option v-for="item in profitLossTransactionTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end pb-0">
              <button type="submit" href="#" class="btn btn-success" id="kt_invoice_submit_button" @click="this.saveData()" data-kt-indicator="on">
                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                </svg>
                </span>
                <strong v-if="!this.isProgressing"> Save </strong> 
                <span class="indicator-progress" v-if="this.isProgressing">
                    Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span> 
              </button>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2 d-none" data-bs-dismiss="modal" aria-label="Close" ref="closeModal">  
              </div> 
              </div>
            </form>
            <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isCompanyPlanSaving == true">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>   
  </div>
</template>


<script>
// Component Alias: `PLC001`
import axios from 'axios';
  
// import Swal from 'sweetalert2';

 
export default {
  name: "PlanCreate",
  data() {
    return {
      isReady: true,
      lists: [],  
      levelList: [{ code: null, name: 'Select an option' }, { code: 1, name: 'Level 1' }, { code: 2, name: 'Level 2' }, { code: 22, name: 'Level 2.2'} ,{ code: 3, name: 'Level 3' } ],
      recordTypeList: [{ code: 1, name: 'Serbest'}, { code: 2, name: 'Sadece Borç'}, { code: 3, name: 'Sadece Alacak'}],
      balanceTypeList: [{ code: 1, name: 'No Control'}, { code: 2, name: 'No Debit'}, { code: 3, name: 'No Credit'}],
      incomeItemList: [{ code: 1, name: 'No'}, { code: 2, name: 'Yes'}],
      balanceItemList: [{ code: 1, name: 'Item 1'}, { code: 2, name: 'Item 2'}, { code: 3, name: 'Item 3'}],
      levelPlansList: [],
      planTypeList: [{ code: 1, name: 'Standard'}],
      accountTypeList: [
        { code: 0, list_type: 0, name: 'Select an option'},
        { code: 10, list_type: 8, name: 'Amortization'},
        { code: 4, list_type: 1, name: 'Bank'},
        { code: 3, list_type: 0, name: 'Cash'},
        { code: 12, list_type: 0, name: 'Cheques'},
        { code: 16, list_type: 6, name: 'Cost Of Sales'},
        { code: 1, list_type: 14, name: 'Customer'},
        { code: 14, list_type: 0, name: 'Difference'},
        { code: 6, list_type: 4, name: 'Expense'},
        { code: 8, list_type: 8, name: 'Fixed Asset'},
        { code: 15, list_type: 17, name: 'Other Account'},
        { code: 5, list_type: 3, name: 'Personnel'},
        { code: 17, list_type: 6, name: 'Profit & Loss Transfer'},
        { code: 9, list_type: 6, name: 'Sales'},
        { code: 7, list_type: 6, name: 'Stock'},
        { code: 2, list_type: 14, name: 'Supplier'},
        { code: 13, list_type: 0, name: 'Turnover'},
        { code: 11, list_type: 0, name: 'VAT Account'},
        { code: 18, list_type: 0, name: 'VAT Calculated'},
      ],
      salesTypeList: [
        { code: 0, name: 'ALL'},
        { code: 1, name: 'Domestic'},
        { code: 2, name: 'Overseas'},
      ],
      vatTypeList: [
        { code: 0, name: 'ALL'},
        { code: 1, name: 'Sales'},
        { code: 2, name: 'Purchase'},
        { code: 3, name: 'Sales Return'},
        { code: 4, name: 'Purchase Return'},
        { code: 5, name: 'VAT Type Expense'}
      ],
      chequeTypeList: [
        { code: 1, name: 'Incoming Cheque'},
        { code: 2, name: 'Outgoing Cheque'},
      ],
      vatCalculatedTypeList: [
        { code: 0, name: 'ALL'},
        { code: 1, name: 'To be Paid'},
        { code: 2, name: 'To be Refund'},
      ],
      currencyList: [],
      customerTypeList: [{ code: null, name: 'ALL'}],
      supplierTypeList: [{ code: null, name: 'ALL'}],
      cashTypeList: [{ code: null, name: 'ALL'}],
      bankAccountTypeList: [
        { id: null, text: 'ALL', code: null, name: 'ALL'}, 
        { id: '1', text: 'Bank Account', code: '1', name: 'Bank Account'}, 
        { id: '2', text: 'Credit Card Account', code: '2', name: 'Credit Card Account' },
        { id: '3', text: 'Loan Account', code: '3', name: 'Loan Account'},
        { id: '4', text: 'Lease Account', code: '4', name: 'Lease Account'},
        { id: '5', text: 'Cheque Account', code: '5', name: 'Cheque Account'},
        { id: '6', text: 'Other Bank Account',  code: '6', name: 'Other Bank Account'}
      ],
      chequeOperationTypeList: [
        { code: 0, name: 'Waiting'},
        { code: 1, name: 'Deposit'},
      ],
      expenseGroupList: [],
      stockGroupList: [],
      assetTypeList: [],
      personnelPositionTypeList: [],
      otherAccountGroupList: [],
      form: { 
          levelSelection: { code: null, name: 'Select an option'},
          level1Selection: { final_code_array: [] },
          level2Selection: { final_code_array: [] },
          level_1_id: null,
          level_2_id: null,
          code: null,
          name: null,  
          plan_level: null,
          balance_type: null,
          income_item: null,
          balance_item: null,
          recordTypeSelection: null,
          balanceTypeSelection: null,
          incomeItemSelection: null,
          balanceItemSelection: null,
          plan_type_id: null,
          account_type_id: null,
          list_type_id: null,
          currency: null,
          sales_type: 0,
          vat_rate: 0.00,
          vat_type: null,
          cheque_type: null,
          cheque_operation_type: 0,
          transfer_account_type: 0,
          is_opening_account: 0,
          is_closing_account: 0,
          is_profit_loss_account: 0,
          profit_loss_transaction_type: 0,
          planTypeSelection: null,
          accountTypeSelection: null,
          cashTypeSelection: null, 
          bankAccountTypeSelection: null,
          personnelPositionTypeSelection: null,
          expenseGroupSelection: null,
          stockGroupSelection: null,
          assetTypeSelection: null,
          customerTypeSelection: null,
          currencySelection: null,
          salesTypeSelection: null,
          vatTypeSelection: null,
          chequeTypeSelection: null,
          chequeOperationTypeSelection: null,
          otherAccountGroupSelection: null,
          policyAccountTypeSelection: null,
          accountSelection: null,
          account_id: '-1',
          isOpeningAccountSelection: null,
          isClosingAccountSelection: null,
          isProfitLossSelection: { code: 1, name: 'No'},
          profitLossTransactionTypeSelection: null,
          transferAccountTypeSelection: null,
          vatCalculatedTypeSelection: null,
      },
      accountsList: [],
      accountTransferParameterList: [
        { code: 1, name: 'No'},
        { code: 2, name: 'Yes'},
      ],
      profitLossTransactionTypeList: [
          { code: 0, name: 'Select an option'},
          { code: 1, name: 'Income Closing'},
          { code: 2, name: 'Expense Closing'},
          { code: 3, name: 'Cost Closing'},
          { code: 4, name: 'Other Income Closing'},
          { code: 5, name: 'Other Expense Closing'},
          // { code: 6, name: 'All'},
      ],
      transferAccountTypeList: [
          { code: -1, name: 'Select an option'},
          { code: 1, name: 'Income Transfer'},
          { code: 2, name: 'Expense Transfer'},
          { code: 3, name: 'Cost Transfer'},
          { code: 4, name: 'Other Income Transfer'},
          { code: 5, name: 'Other Expense Transfer'},
          { code: 0, name: 'All Transfers'},
      ],
      planSettingsData: {
        id: null,
        level_1: null,
        level_2: null,
        level_3: null,
        brace_character: null,
        level_1_code: null,
        level_2_code: null
      },
      activePeriodInfo: {
        company_account_id: null,
        cost_of_sales_method: 1,
        cost_of_sales_method_status: 1,
        created_at: null,
        created_by: null,
        end_date: null,
        id: null,
        is_active: 1,
        is_automatic_task: 0,
        is_online_accounting: 1,
        is_vat_active: 0,
        month_count: 12,
        period_id: null,
        period_year_month_short: null,
        period_year_short: null,
        plan_template_id: null,
        plan_template_name: null,
        start_date: null,
        text: null,
      },
      isLoadingPlans: false,
      isCompanyPlanSaving: false,
      companyAccountsList: [],
      policyAccountTypeList: [{code: '-1', name: 'Select an option'}, {code: 1 , name: 'Insurance Commission'}, {code: 2 , name: 'Vendor Commission'}, {code: 3, name: 'Policy Taxes'}],
    };
  },
  computed: {
    onlyLevel2PlansList() {
      return this.levelPlansList.filter(p => { 
        // Yalnızca Level 2 olanları getirmek için kullanılır. Level2.2'de diğer Level 2.2'ler kullanılmazlar.
        return p.id == null || p.is_level_2_to_level_2 == 0;
      });
    },
    checkIfAccountView(){  
        switch(this.form.accountTypeSelection.code) {
          case 1:
              return this.form.customerTypeSelection == null ? false : ((this.form.customerTypeSelection.code == 'ALL' || this.form.customerTypeSelection.code == 1 || this.form.customerTypeSelection.code == null )? true : false); 
          case 2:
              return this.form.supplierTypeSelection == null ? false : ((this.form.supplierTypeSelection.code == 'ALL' || this.form.supplierTypeSelection.code == 1 || this.form.supplierTypeSelection.code == null )? true : false);
          case 3:
              return this.form.cashTypeSelection == null ? false : ((this.form.cashTypeSelection.code == 'ALL' || this.form.cashTypeSelection.code == 1 || this.form.cashTypeSelection.code == null )? true : false);
          case 4:
              return this.form.bankAccountTypeSelection == null ? false : ((this.form.bankAccountTypeSelection.code == 'ALL' || this.form.bankAccountTypeSelection.code == 1 || this.form.bankAccountTypeSelection.code == null )? true : false);
          case 5:
              return this.form.personnelPositionTypeSelection == null ? false : ((this.form.personnelPositionTypeSelection.code == 'ALL' || this.form.personnelPositionTypeSelection.code == 1 || this.form.personnelPositionTypeSelection.code == null )? true : false);
          case 6:
              return this.form.expenseGroupSelection == null ? false : ((this.form.expenseGroupSelection.code == 'ALL' || this.form.expenseGroupSelection.code == 1 || this.form.expenseGroupSelection.code == null )? true : false);
          case 7:
              return this.form.stockGroupSelection == null ? false : ((this.form.stockGroupSelection.code == 'ALL' || this.form.stockGroupSelection.code == 1 || this.form.stockGroupSelection.code == null )? true : false);
          case 8:
              return this.form.assetTypeSelection == null ? false : ((this.form.assetTypeSelection.code == 'ALL' || this.form.assetTypeSelection.code == 1 || this.form.assetTypeSelection.code == null )? true : false);
          case 15:
              return this.form.otherAccountGroupSelection == null ? false : ((this.form.otherAccountGroupSelection.code == 'ALL' || this.form.otherAccountGroupSelection.code == 1 || this.form.otherAccountGroupSelection.code == null )? true : false);
          default:
            return false;
        } 
    } 
  },
  methods: {
    getData() {  
      this.getCurrencies();
      this.isCompanyPlanSaving = false;
      this.activePeriodInfo = this.$getSessionStorage("active_period");
      this.form = { 
          levelSelection: { code: null, name: 'Select an option'},
          level1Selection: { final_code_array: [] },
          level2Selection: { final_code_array: [] },
          level_1_id: null,
          level_2_id: null,
          code: null,
          name: null,  
          plan_level: null,
          balance_type: null,
          income_item: null,
          balance_item: null,
          recordTypeSelection: null,
          balanceTypeSelection: null,
          incomeItemSelection: null,
          balanceItemSelection: null,
          subLevelSelection: 'level_1',
          plan_type_id: null,
          account_type_id: null,
          list_type_id: null,
          currency: null,
          sales_type: 0,
          vat_rate: 0,
          vat_type: null,
          cheque_type: null,
          cheque_operation_type: 0,
          transfer_account_type: 0,
          is_opening_account: 0,
          is_closing_account: 0,
          is_profit_loss_account: 0,
          profit_loss_transaction_type: 0,
          planTypeSelection: null,
          accountTypeSelection: null,
          cashTypeSelection: null, 
          bankAccountTypeSelection: null,
          personnelPositionTypeSelection: null,
          expenseGroupSelection: null,
          stockGroupSelection: null,
          assetTypeSelection: null,
          customerTypeSelection: null,
          currencySelection: null,
          salesTypeSelection: null,
          vatTypeSelection: null,
          chequeTypeSelection: null,
          chequeOperationTypeSelection: null,
          otherAccountGroupSelection: null,
          policyAccountTypeSelection: null,
          isOpeningAccountSelection: null,
          isClosingAccountSelection: null,
          isProfitLossSelection: null,
          profitLossTransactionTypeSelection: null,
          transferAccountTypeSelection: null,
          vatCalculatedTypeSelection: null,
      };
      const params = {
        id: this.$getUser().session_id
      }
      axios.get('/api/AccountingPlans/AccountingPlanSettings/' + params.id)
        .then((response) => {      
            this.planSettingsData = { 
                id: response.data[0].id, 
                level_1: response.data[0].level_1,
                level_2: response.data[0].level_2,
                level_3: response.data[0].level_3,
                brace_character: response.data[0].brace_character
            };  
            this.form.recordTypeSelection = this.recordTypeList[0];
            this.form.balanceTypeSelection = this.balanceTypeList[0];
            this.form.incomeItemSelection = this.incomeItemList[0];
            this.form.balanceItemSelection = this.balanceItemList[0];
            this.form.planTypeSelection = this.planTypeList[0];
            this.form.accountTypeSelection = this.accountTypeList[0];
            this.form.currencySelection = this.currencyList[0];
            this.form.customerTypeSelection = this.customerTypeList[0];
            this.form.supplierTypeSelection = this.supplierTypeList[0];
            this.form.cashTypeSelection = this.cashTypeList[0];
            this.form.bankAccountTypeSelection = this.bankAccountTypeList[0];
            this.form.personnelPositionTypeSelection = this.personnelPositionTypeList[0];
            this.form.expenseGroupSelection = this.expenseGroupList[0];
            this.form.stockGroupSelection = this.stockGroupList[0];
            this.form.assetTypeSelection = this.assetTypeList[0];
            this.form.salesTypeSelection = this.salesTypeList[0];
            this.form.vatTypeSelection = this.vatTypeList[0]; 
            this.form.chequeTypeSelection = this.chequeTypeList[0]; 
            this.form.chequeOperationTypeSelection = this.chequeOperationTypeList[0];
            this.form.otherAccountGroupSelection = this.otherAccountGroupList[0];  
            this.accountsList.unshift({ code: null, name: 'ALL'});
            this.form.accountSelection = this.accountsList[0];
            this.form.isOpeningAccountSelection = this.accountTransferParameterList[0];
            this.form.isClosingAccountSelection = this.accountTransferParameterList[0];
            this.form.isProfitLossSelection = this.accountTransferParameterList[0];
            this.form.profitLossTransactionTypeSelection = this.profitLossTransactionTypeList[0];
            this.form.transferAccountTypeSelection = this.transferAccountTypeList[0];
            this.form.vatCalculatedTypeSelection = this.vatCalculatedTypeList[0];
            this.form.policyAccountTypeSelection = this.policyAccountTypeList[0];
        })
        .catch(function () {  
        });  
    },
    saveData(){
      if(this.form.levelSelection.code == null || this.form.name == null || this.form.code == null) {
        this.$swal("Failed", " Required fields are missing..!", 'warning' );
        return;
      }
      this.isProgressing = true;
      const params = this.form;
      const that = this;
      this.isCompanyPlanSaving = true;
        params.session_id = this.$getUser().session_id;
        params.plan_level = params.levelSelection.code;

        // Level 1
        if(params.plan_level == 1){
          params.level_1_id = null;
          params.level_2_id = null;
        }

        // Level 2 to Level 1
        if(params.plan_level == 2){
          params.level_1_id = params.level1Selection.id; 
          params.level_2_id = null;

          if(params.level1Selection.id == null) {
            this.isProgressing = false;
            this.isCompanyPlanSaving = false;
            this.$swal("Failed", " Level-1 must be selected..!", 'warning' );
            return;
          }
        }
        
        // Level 2 to Level 1
        if(params.plan_level == 22){
          params.level_1_id = null; 
          params.level_2_id = params.level2Selection.id;
          params.plan_level = 2;

          if(params.level2Selection.id == null) {
            this.isProgressing = false;
            this.isCompanyPlanSaving = false;
            this.$swal("Failed", " Level-2 must be selected..!", 'warning' );
            return;
          }
        }
        
        // Level 3 to Level 2-Main or Sub
        if(params.plan_level == 3){
          params.level_2_id = params.level2Selection.id;
          params.level_1_id = null;

          if(params.level2Selection.id == null) {
            this.isProgressing = false;
            this.isCompanyPlanSaving = false;
            this.$swal("Failed", " Level-2 must be selected..!", 'warning' );
            return;
          }
        }  
         
        params.balance_type = params.balanceTypeSelection.code;
        params.income_item = params.incomeItemSelection.code;
        params.balance_item = params.balanceItemSelection.code;

        params.plan_type_id = params.planTypeSelection.code;
        params.account_type_id = params.accountTypeSelection.code;
        params.is_opening_account = params.isOpeningAccountSelection.code;
        params.is_closing_account = params.isClosingAccountSelection.code;
        params.is_profit_loss_account = params.isProfitLossSelection.code;
        params.profit_loss_transaction_type = params.profitLossTransactionTypeSelection.code;
        params.currency = null;
        params.sales_type = 0;
        params.vat_type = 0;
        params.cheque_type = 0;
        params.cheque_operation_type = 0;

        params.list_type_id = null;
        if (params.account_type_id == 4) {
          params.list_type_id = params.bankAccountTypeSelection.id;
        } 
        if (params.account_type_id == 5) {
          params.list_type_id = params.personnelPositionTypeSelection.id;
        }
        if (params.account_type_id == 6) {
          params.list_type_id = params.expenseGroupSelection.id;
          params.policy_account_type = params.policyAccountTypeSelection.code;
        } 
        if (params.account_type_id == 7 || params.account_type_id == 9 || params.account_type_id == 16) {
          params.list_type_id = params.stockGroupSelection.id;
        } 
        if (params.account_type_id == 8 || params.account_type_id == 10) {
          params.list_type_id = params.assetTypeSelection.id;
        }
        if (params.account_type_id == 15) {
          params.list_type_id = params.otherAccountGroupSelection.id;
          params.policy_account_type = params.policyAccountTypeSelection.code;
        }
        if (params.account_type_id == 1) {
          params.list_type_id = params.customerTypeSelection.id;
        }
        if (params.account_type_id == 2) {
          params.list_type_id = params.supplierTypeSelection.id;
        }
        if (params.account_type_id == 11) {
          params.vat_type = params.vatTypeSelection.code;
        } else {
          params.vat_rate = 0;
        }

        if (params.account_type_id == 12) {
          params.cheque_type = params.chequeTypeSelection.code;
          params.cheque_operation_type = params.cheque_type == 1 ? params.chequeOperationTypeSelection.code : 0;
        }
        if (params.account_type_id == 3 || params.account_type_id == 4 ) {
          params.currency = params.currencySelection.code;
        }
        if (params.account_type_id == 9 || params.account_type_id == 16) {
          params.sales_type = params.salesTypeSelection.code;
        }
        if (params.account_type_id == 17) {
          params.transfer_account_type = params.transferAccountTypeSelection.code;
        }
        params.company_id = this.$getSessionStorage("cp_record_id");
        params.accounting_period_id = this.$getSessionStorage("active_period").id; 
        params.account_id = this.form.account_id;
        if(!this.checkIfAccountView){
          params.account_id = null;
        }
        if (params.account_type_id == 18) {
          params.vat_calculated_type = params.vatCalculatedTypeSelection.code;
        }

        if (params.account_id == '-1') {params.account_id = null;}
        axios.post('/api/CompanyAccountingPlans/CreateAccountingPlan', params, {'Content-Type': 'application/json'})
          .then((response) => { 
              if(!response.data.error && response.data != null && response.data.length == 36){   
                  this.$swal("Success", "Data saved succesfully..!", 'success');  
                  this.$refs.closeModal.click();
                  this.$parent.getData();
              }
              this.isCompanyPlanSaving = false;
          })
          .catch(function (error) {
              console.log(error);
              that.isCompanyPlanSaving = false;
              that.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
              
          }) 
          .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
                that.isCompanyPlanSaving = false;
            }, 500);
        });   
      },
    onChangeSelectablePlan(){
      this.isReady = false;
      if (this.form.levelSelection.code == 3) {
        this.accountTransferParameterList.forEach(element => {
          if (element.code == this.form.level2Selection.is_opening_account) {
            this.form.isOpeningAccountSelection = element;
          }
          if (element.code == this.form.level2Selection.is_closing_account) {
            this.form.isClosingAccountSelection = element;
          }
          if (element.code == this.form.level2Selection.is_profit_loss_account) {
            this.form.isProfitLossSelection = element;
          }
        });
        if (this.form.isProfitLossSelection.code == 2) {
          this.profitLossTransactionTypeList.forEach(element => {
            if (element.code == this.form.level2Selection.profit_loss_transaction_type) {
              this.form.profitLossTransactionTypeSelection = element;
            }
          });
        }
      }
      if (this.form.levelSelection.code == 2) {
        this.accountTransferParameterList.forEach(element => {
          if (element.code == this.form.level1Selection.is_profit_loss_account) {
            this.form.isProfitLossSelection = element;
          }
        });    
      }
      setTimeout(() => {
          this.isReady = true;
      }, 100);
    },
    onChangeLevel(){ 
      this.isLoadingPlans = true;
      const that = this;
      setTimeout(() => {  
          try{
               /**
               * Level 1 seçimi için --
               * Level 2 seçimi için Level 1'ler çağrılmalıdır.
               * Level 2.2 seçimi için Level 2'ler çağrılmalıdır.
               * Level 3 seçimi için Level 2 ve Level 2.2'ler çağrılmalıdır.
               */
              this.levelPlansList = [];
              this.form.level1Selection = { final_code_array: [] };
              this.form.level2Selection = { final_code_array: [] };
              this.form.code = null;

              var requestedLevel = 0;
              if(parseInt(this.form.levelSelection.code) == 2){
                requestedLevel = 1;
              }
              if(parseInt(this.form.levelSelection.code) == 22){
                requestedLevel = 2;
              }
              if(parseInt(this.form.levelSelection.code) == 3){
                requestedLevel = 2;
              }  
              const queryParameter = {
                plan_level: parseInt(requestedLevel),
                company_id: this.$getSessionStorage("cp_record_id"),
                accounting_period_id: this.$getSessionStorage("active_period").id,
                session_id: this.$getUser().session_id
              };
              axios.post('/api/CompanyAccountingPlans/GetAccountingPlanByLevel', queryParameter, {'Content-Type': 'application/json'}) 
                .then((response) => {     
                    this.levelPlansList = response.data;   
                    this.levelPlansList.forEach((value) => {
                      var splits = []; 
                      splits = value.final_code.split(/([this.planSettingsData.brace_character])/);
                      splits.push(this.planSettingsData.brace_character);
                      value.final_code_array = splits; 
                    });   

                    this.levelPlansList.unshift(
                      {
                        balance_item: null,
                        balance_type: null,
                        code: "0",
                        final_code: "0",
                        id: null,
                        income_item: null,
                        is_level_2_to_level_2: null,
                        is_selectable_for_level_3: null,
                        level_1_code: null,
                        level_1_id: null,
                        level_2_code: null,
                        level_2_id: null,
                        name: "Select an Option",
                        plan_level: null,
                        final_code_array: ['0', '.']
                      }
                    );

                    if(this.form.levelSelection.code == 2){
                      this.form.level1Selection = this.levelPlansList[0];
                    }
                    if(this.form.levelSelection.code == 22){
                      this.form.level2Selection = this.levelPlansList[0];
                    }
                    if(this.form.levelSelection.code == 3){
                      this.form.level2Selection = this.levelPlansList[0];
                    }
                })
                .catch(function () { 
                  this.levelPlansList = [];
                  this.form.level1Selection = { final_code_array: [] };
                  this.form.level2Selection = { final_code_array: [] };
                });  

          }catch(Err){
            console.log(Err);
          } 
          finally{
            setTimeout(() => {
                that.isLoadingPlans = false;
            }, 500);
          }
      
      }, 200);
    },
    getListData() {
      if (this.form.accountTypeSelection.list_type == 0){ 
          setTimeout(() => {
             this.getCompanyAccounts();
          }, 500);
        return;
      } 
      this.$Progress.start();
      this.lists = [];
      const params = {
        session_id: this.$getUser().session_id,
        get_access_status: 0,
        list_type: this.form.accountTypeSelection.list_type,
        type_id: this.form.accountTypeSelection.code
      };
      axios.post('/api/Lists/GetLists', params, {'Content-Type': 'application/json'})
        .then((response) => {
          if(this.form.accountTypeSelection.code == 4){ 
            this.form.bankAccountTypeSelection = this.bankAccountTypeList[0];
          } 
          if(this.form.accountTypeSelection.code == 5){
            this.personnelPositionTypeList = response.data; 
            this.personnelPositionTypeList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.personnelPositionTypeSelection = this.personnelPositionTypeList[0];
          }
          if(this.form.accountTypeSelection.code == 6){
            this.expenseGroupList = response.data;
            this.expenseGroupList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.expenseGroupSelection = this.expenseGroupList[0];
          } 
          if(this.form.accountTypeSelection.code == 7 || this.form.accountTypeSelection.code == 9 || this.form.accountTypeSelection.code == 16){ 
            this.stockGroupList = response.data;
            this.stockGroupList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.stockGroupSelection = this.stockGroupList[0];
          } 
          if(this.form.accountTypeSelection.code == 8 || this.form.accountTypeSelection.code == 10){
            this.assetTypeList = response.data; 
            this.assetTypeList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.assetTypeSelection = this.assetTypeList[0];
          }
          if(this.form.accountTypeSelection.code == 15){
            this.otherAccountGroupList = response.data; 
            this.otherAccountGroupList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.otherAccountGroupSelection = this.otherAccountGroupList[0];
          }
          if(this.form.accountTypeSelection.code == 1){ 
            this.customerTypeList = response.data;
            this.customerTypeList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.customerTypeSelection = this.customerTypeList[0];
          } 
          if(this.form.accountTypeSelection.code == 2){ 
            this.supplierTypeList = response.data;
            this.supplierTypeList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.supplierTypeSelection = this.supplierTypeList[0];
          } 
          setTimeout(() => {
             this.getCompanyAccounts();
          }, 500);
          this.$Progress.finish();
        })
        .catch(function (error) {
          console.log(error);  
          this.$Progress.finish();
        });
    },
    getCurrencies(){  
        const params = {
            session_id: this.$getUser().session_id,
            get_access_status: 0
        };    
        axios.post('/api/Lists/Currencies', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.currencyList = response.data;
          this.currencyList.unshift({ code: null, name: 'ALL'});
          this.$Progress.finish();
        })
        .catch(function (error) {
          console.log(error); 
          this.$Progress.fail();
        });
    },
    getCompanyAccounts(){
        var isAvailable = false; 
        switch(this.form.accountTypeSelection.code) {
          case 1:
              isAvailable = this.form.customerTypeSelection == null ? false : ((this.form.customerTypeSelection.code == 'ALL' || this.form.customerTypeSelection.code == 1 || this.form.customerTypeSelection.code == null) ? true : false);
            break;
          case 2:
              isAvailable = this.form.supplierTypeSelection == null ? false : ((this.form.supplierTypeSelection.code == 'ALL' || this.form.supplierTypeSelection.code == 1 || this.form.supplierTypeSelection.code == null) ? true : false);
            break;
          case 3:
              isAvailable = this.form.cashTypeSelection == null ? false : ((this.form.cashTypeSelection.code == 'ALL' || this.form.cashTypeSelection.code == 1 || this.form.cashTypeSelection.code == null) ? true : false);
            break;
          case 4:
              isAvailable = this.form.bankAccountTypeSelection == null ? false : ((this.form.bankAccountTypeSelection.code == 'ALL' || this.form.bankAccountTypeSelection.code == 1 || this.form.bankAccountTypeSelection.code == null) ? true : false);
            break;
          case 5:
              isAvailable = this.form.personnelPositionTypeSelection == null ? false : ((this.form.personnelPositionTypeSelection.code == 'ALL' || this.form.personnelPositionTypeSelection.code == 1 || this.form.personnelPositionTypeSelection.code == null) ? true : false);
            break;
          case 6:
              isAvailable = this.form.expenseGroupSelection == null ? false : ((this.form.expenseGroupSelection.code == 'ALL' || this.form.expenseGroupSelection.code == 1 || this.form.expenseGroupSelection.code == null) ? true : false);
            break;
          case 7:
              isAvailable = this.form.stockGroupSelection == null ? false : ((this.form.stockGroupSelection.code == 'ALL' || this.form.stockGroupSelection.code == 1 || this.form.stockGroupSelection.code == null) ? true : false);
            break;
          case 8:
              isAvailable = this.form.assetTypeSelection == null ? false : ((this.form.assetTypeSelection.code == 'ALL' || this.form.assetTypeSelection.code == 1 || this.form.assetTypeSelection.code == null) ? true : false);
            break;
          case 15:
              isAvailable = this.form.otherAccountGroupSelection == null ? false : ((this.form.otherAccountGroupSelection.code == 'ALL' || this.form.otherAccountGroupSelection.code == 1 || this.form.otherAccountGroupSelection.code == null) ? true : false);
            break;
          default:
            isAvailable = false;
        }
        if (isAvailable) {
              this.companyAccountsList = [];
              const params = {
                  session_id: this.$getUser().session_id,
                  company_id: this.$getSessionStorage("cp_record_id"),
                  account_type: this.form.accountTypeSelection.code
              };    
              axios.post('/api/AccountingPlans/CompanyAccountsForPlans', params, {'Content-Type': 'application/json'})
                  .then((response) => { 
                      this.companyAccountsList = response.data;
                      this.companyAccountsList.unshift({ id: '-1', text: 'ALL'});
                      this.form.account_id = '-1';
                      this.$Progress.finish();
                  })
                  .catch(function (error) {
                      console.log(error); 
                      this.$Progress.fail();
                  });
        }
        else{
          this.companyAccountsList = [];
          this.companyAccountsList.unshift({ id: '-1', text: 'ALL'});
          this.form.account_id = '-1';
        }
    }
  },
  created() {
 
  }
}; 
</script>