<template>
  <div>
    <div class="content flex-row-fluid pb-0" id="kt_content">
      <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
        <li class="nav-item">
            <a class="nav-link" :class="{'active': this.activeTab == 'General'}" data-bs-toggle="tab" href="#kt_general_tab_0" target="_self"
            @click="this.activeTab = 'General'">
                <h5 class="text-gray-800"> General </h5>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :class="{'active': this.activeTab == 'UserGroup'}" data-bs-toggle="tab" href="#kt_user_group_tab_1" target="_self"
            @click="this.activeTab = 'UserGroup', getUserGroupDetails()">
                <h5 class="text-gray-800"> User Group </h5>
            </a>
        </li>
      </ul>
      <div class="card card-xxl-stretch tab-pane fade show active" id="kt_general_tab_0" role="tabpanel" v-if="this.activeTab == 'General'">
        <div class="row">                   
          <div class="col-lg-12 mt-0"> 
            <div class="d-flex flex-row row">
                <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                  <div class="d-flex flex-column-fluid card card-xxl-stretch">
                    <div class="alert alert-dismissible bg-light-warning border border-warning d-flex flex-column flex-sm-row w-100 p-5 mb-5">
                      <i class="ki-duotone ki-search-list fs-2hx text-primary me-4 mb-5 mb-sm-0"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                      <div class="d-flex flex-column pe-0 pe-sm-10">
                          <span> A verification link will be sent to the entered e-mail address. The account must be verified by setting a password on the link sent. 
                            The user will not be able to access the system until the account is verified.
                          </span>
                      </div>
                      <button type="button" class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                          <i class="ki-duotone ki-cross fs-1 text-primary"><span class="path1"></span><span class="path2"></span></i>
                      </button>
                    </div>
                  </div> 
                </div>
                <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                        <div class="card-body">
                            <div class="row">
                              <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  Name Surname
                                </label>
                                <input type="text" class="form-control" placeholder="" name="name" v-model="form.name_surname"> 
                              </div>
                              <!-- <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  User Group
                                </label>
                                <Select2 v-model="form.user_group_id" :options="userGroupList" :settings="{width: '100%'}" />
                              </div> -->
                              <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3 required">
                                  Email
                                </label>
                                <input type="text" class="form-control" placeholder="" name="name" v-model="form.user_mail" autocomplete="off" :class="{'border border-danger': isValidEmail == false}"> 
                                <small class="text-danger" v-if="!isValidEmail && form.user_mail !== null && form.user_mail !== '' ">Email is not valid</small>
                              </div>
                              <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  Permission Type
                                </label>
                                <Select2 v-model="form.permission_type" :options="permissionTypes" :settings="{width: '100%'}" @select="this.form.isAllowListAllData = this.form.permission_type == 1 ? true : false;" />
                              </div>
                            </div> 
                        </div>
                    </div> 
                </div>
                <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                        <div class="card-body">
                            <div class="row">
                              <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  Phone
                                </label>
                                <div class="input-group">
                                  <span class="input-group-text fs-7 text-primary" id="basic-addon1">
                                    {{form.company_country_code}} {{form.phone_country_code}}
                                  </span>
                                  <input type="text" class="form-control" name="phone" v-model="form.phone">
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  Gender
                                </label>
                                <Select2 v-model="form.gender" :options="genderList" :settings="{ width: '100%' }"/>
                              </div>
                              <div class="col-lg-6">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  Date of Birth
                                </label>
                                <Datepicker name="date_of_birth" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.date_of_birth" 
                                format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" :maxDate="new Date()"/>
                              </div>
                              <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  User Assignment
                                </label>
                                <Select2 v-model="form.user_assignment_id" :options="userAssignmentList" :settings="{width: '100%'}" />
                              </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
          </div>
          <div class="col-lg-12">
              <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                  <div class="card-body pb-0">
                      <form id="kt_ecommerce_settings_general_form"
                          class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                          <div class="fv-row mb-7 row">
                              <div class="col-lg-6">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  Login Method
                                </label> 
                                <Select2 v-model="form.login_method" :options="loginMethods" :settings="{width: '100%'}" />
                              </div>
                              <div class="col-lg-6" v-if="form.login_method == 2">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  Authentication Type
                                </label> 
                                <Select2 v-model="form.authentication_type" :options="authenticationTypes" :settings="{width: '100%'}" />
                              </div>
                              <div class="col-lg-6">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  Validity Type
                                </label> 
                                <Select2 v-model="form.validity_type" :options="validityTypes" :settings="{width: '100%'}" />
                              </div>
                              <div class="col-lg-6" v-if="form.validity_type == 2">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  Validity Start Date
                                  <small v-if="form.validity_date_start == null" class="text-danger ms-3"> Please enter a valid date </small>
                                </label> 
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_start" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                              </div>
                              <div class="col-lg-6" v-if="form.validity_type == 2">
                                <label class="fs-6 fw-bold form-label mt-3">
                                  Validity End Date
                                  <small v-if="form.validity_date_end == null" class="text-danger ms-3"> Please enter a valid date </small>
                                </label> 
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_end" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />  
                              </div>
                              <div class="d-flex col-lg-12 mt-3">
                                <div class="col-lg">
                                  <label class="fs-6 fw-bold form-label mt-3">Is Owner?</label> 
                                  <div class="form-check mt-3 mb-3">
                                    <input class="form-check-input" type="checkbox" id="isOwner" v-model="form.isOwner">
                                  </div> 
                                </div>
                                <div class="col-lg">
                                  <label class="fs-6 fw-bold form-label mt-3">Is ChatBi User?</label> 
                                  <div class="form-check mt-3 mb-3">
                                    <input class="form-check-input" type="checkbox" id="isChatbiUser" v-model="form.isChatbiUser">
                                  </div>
                                </div>
                                <div class="col-lg">
                                  <label class="fs-6 fw-bold form-label mt-3">Allow List All Data?</label> 
                                  <div class="form-check mt-3 mb-3">
                                    <input class="form-check-input" type="checkbox" id="isAllowListAllData" v-model="form.isAllowListAllData">
                                  </div>
                                </div>
                                <div class="col-lg">
                                  <label class="fs-6 fw-bold form-label mt-3">Allow Export?</label> 
                                  <div class="form-check mt-3 mb-3">
                                    <input class="form-check-input" type="checkbox" id="isAllowExport" v-model="form.isAllowExport">
                                  </div> 
                                </div>
                                <div class="col-lg">
                                  <label class="fs-6 fw-bold form-label mt-3">Allow Cancel Accounting Integr.?</label> 
                                  <div class="form-check mt-3 mb-3">
                                    <input class="form-check-input" type="checkbox" id="isAllowCancelAccountingIntegration" v-model="form.isAllowCancelAccountingIntegration">
                                  </div> 
                                </div>
                              </div>
                              <div class="col-lg-12">
                                  <label class="fs-6 fw-bold form-label mt-3">
                                      Description
                                  </label>
                                  <textarea type="text" rows="2" class="form-control" placeholder="" name="name" v-model="form.user_description" /> 
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
          <div class="col-lg-12 mt-0">
            <div class="d-grid gap-2"> 
                <button type="button" class="btn btn-lg btn-success" @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                    </svg>
                    </span>
                      <strong v-if="!this.isProgressing"> Save Company User </strong> 
                        <span class="indicator-progress" v-if="this.isProgressing">
                            Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                </button>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2 d-none" data-bs-dismiss="modal" aria-label="Close" ref="closeModal">  
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="card card-xxl-stretch tab-pane fade show active" id="kt_user_group_tab_1" role="tabpanel" v-if="this.activeTab == 'UserGroup'">
        <div class="card-body"> 
            <form id="group_user_form" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">  
                <div class="fv-row mb-6 row">   
                    <div class="col-lg-4">
                        <div class="fv-row">
                            <label class="fs-6 fw-bold form-label mt-3">
                              Add User to Group
                            </label> 
                            <Select2 v-model="user_group_id" :options="userGroupList" :settings="{width: '100%'}"/> 
                        </div>
                    </div>
                </div> 
            </form>
            <div class="separator my-10"></div>
            <div class="d-flex flex-stack flex-wrap my-5">
                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" @click="addUserGroup()" :disabled="this.user_group_id == '-1'">
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                    fill="currentColor">
                                </rect>
                            </svg>
                        </span>
                        Add
                    </button>
                    <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteUserGroup()" 
                        :disabled="isButtonDisabled == null || isButtonDisabled == undefined">
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                        </span>
                        Remove
                    </button>
                    <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllUsersGroup()" :disabled="this.groupUserList.length == 0">
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                        </span>
                        Remove All (!)
                    </button>
                </div>
            </div>
            <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
              <div class="table-responsive">
                <DxDataGrid :show-borders="true" 
                          key-expr="id" 
                          :data-source="groupUserList" 
                          :allow-column-reordering="true"  
                          :allow-column-resizing="true" 
                          :column-auto-width="true" 
                          :row-alternatin-enabled="true"
                          :hover-state-enabled="true" 
                          :show-row-lines="true" 
                          @exporting="this.$onExporting($event , 'User-Group-List')">

                          <DxFilterRow :visible="true"/>
                          <DxHeaderFilter :visible="true"/>
                          <DxGroupPanel :visible="true"/>      
                          <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                          <DxPaging :page-size="10000"/>
                          <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                          <DxSearchPanel :visible="true"/>
                          <DxScrolling
                            mode="virtual"
                            row-rendering-mode="virtual"
                          />
                          <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template"/>
                          <DxColumn data-field="name" :caption="'Group Name'" cell-template="name-cell-template"/> 
                          <DxColumn data-field="description" :caption="'Description'" cell-template="description-cell-template"/> 
                          <DxColumn data-field="is_active" :caption="'Active?'" cell-template="is_active-cell-template" alignment="center"/> 
                          <template #is-selected-cell-template="{ data }"> 
                            <span>
                              <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                              </div>
                            </span>
                          </template>
                          <template #name-cell-template="{ data }">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1" >
                                  {{ data.data.name }}
                              </a>
                          </template>
                          <template #description-cell-template="{ data }">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1" >
                                  {{ data.data.description }}
                              </a>
                          </template>
                          <template #is_active-cell-template="{ data }">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1" >
                                <span class="svg-icon svg-icon-1 svg-icon-success" v-if="data.data.is_active">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                    <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
                                  </svg>
                                </span>
                              </a>
                          </template>
                  </DxDataGrid>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div> 
</template>


<script>
import axios from 'axios';
import moment from 'moment'; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
export default {
  name: "CompanyUserCreate",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  props: {
      controllerId: Object,
      phoneCountryCode: Object,
      companyCountryCode: Object
  },
  data() {
    return {
      activeTab: 'General',
      isProgressing: false,
      permissionTypes: [{ id: 1, text: "Admin" }, { id: 2, text: "Client" }],
      loginMethods: [{ id: 1, text: "Standard" }, { id: 2, text: "2FA" }],
      authenticationTypes: [{ id: 1, text: "SMS" }, { id: 2, text: "Google 2FA" }],
      validityTypes: [{ id: 1, text: "Always" }, { id: 2, text: "Date Range" }],
      userAssignmentList: [],
      isHide: true,
      form: { 
        account_type: 3,
        permission_type: 1,
        company_account_id: null,
        user_assignment_id: '-1',
        controller_account_id: '-1',
        user_mail: null,
        user_password: null,
        name_surname: null,
        phone: null,
        user_description: null,
        login_method : 1,
        authentication_type: 1,
        validity_type: 1,
        validity_date_start: new Date(),
        validity_date_end: new Date(),
        password_validity: -1,
        is_active: 1,
        isActive: true,
        session_id: null,
        isOwner: false,
        is_owner: 0,
        isChatbiUser: false,
        is_chatbi_user: 0,
        phone_country_code: null,
        company_country_code: null,
        isAllowExport: false,
        is_allow_export : 0,
        isAllowListAllData: true,
        is_allow_list_all_data : 1,
        user_group_id: 1,
        isAllowCancelAccountingIntegration: false,
        is_allow_cancel_accounting_integration: 0,
        gender: '-1',
        date_of_birth: null
      },
      user_group_id: '-1',
      groupUserList:[],
      userGroupList: [{ id: 1, text: "Not Selected" }],
      selectedUserGroupId: null,
			genderList: [
				{id: '-1', text: 'Select an option'},
				{id: 'M', text: 'Male'},
				{id: 'F', text: 'Female'},
				{id: 'O', text: 'Other'},
			],
    };
  },
  computed: {
    isValidEmail() {
			return (this.form.user_mail == null || this.form.user_mail == '' || this.form.user_mail == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.user_mail);
		},
    isButtonDisabled() {
      return this.groupUserList.find(item => item.isSelected == true && item.id !== '' );
    }
  },
  methods: {
    getData() {
      this.userAssignmentList = [];
      this.groupUserList =[];
      this.userGroupList = [{ id: 1, text: "Not Selected" }];
      this.clearForm();

      const parameters = {
        session_id: this.$getUser().session_id,
        get_access_status: 0,
        list_type: 18, // User Assignment List: 18
        account_type: 3
      };        
      const requestOne =  axios.post('/api/Lists/GetLists', parameters, {'Content-Type': 'application/json'});
      const requestTwo =  axios.post('/api/GeneralUserParameters/Get', parameters, {'Content-Type': 'application/json'});
      axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
        this.userAssignmentList = responses[0].data;
        this.userAssignmentList.unshift({ id: '-1', text:  'Select an option'});

        if(!this.$isSystemAdmin()) {
          this.userAssignmentList.forEach((value) => {
            if(value.is_default) {
              this.form.user_assignment_id = value.id;
            }
          });
        }

        this.form.login_method = responses[1].data.login_method == 0 ? 1 : responses[1].data.login_method;
        this.form.authentication_type = responses[1].data.authentication_type == 0 ? 1 : responses[1].data.authentication_type;
        this.form.validity_type = responses[1].data.validity_type == 0 ? 1 : responses[1].data.validity_type;        
        this.form.validity_date_start = responses[1].data.validity_date_start;
        this.form.validity_date_end = responses[1].data.validity_date_end;

        this.form.phone_country_code = this.phoneCountryCode;
        this.form.company_country_code = this.companyCountryCode;
      }))
      .catch(errors => { 
        console.error(errors); 
      });
    },
    getUserGroupDetails() {
      const parameters = {
        company_id: this.$getSessionStorage("cp_record_id"),
        session_id: this.$getUser().session_id
      };  
      axios.post('/api/UserGroup/Get', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.userGroupList = response.data;
          this.userGroupList.unshift({ id: '-1', text: "Not Selected" });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    TogglePasswordVisibility() {
      this.isHide = !this.isHide;
      var x = document.getElementById("Ps_Password");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this; 
      params.session_id = this.$getUser().session_id;
      params.is_active = params.isActive == true ? 1 : 0;
      params.is_owner = params.isOwner == true ? 1 : 0;
      params.is_chatbi_user = params.isChatbiUser == true ? 1 : 0;
      params.company_account_id = params.account_type == '-1' ? null : params.company_account_id;
      params.user_assignment_id = params.user_assignment_id == -1 ? null : params.user_assignment_id;
      params.gender = params.gender == -1 ? null : params.gender;
      params.controller_account_id = this.controllerId;
      params.company_account_id = this.$getSessionStorage("cp_record_id");
      if (params.validity_type == 2) {
        params.validity_date_start = params.validity_date_start !== null ? moment(params.validity_date_start).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.validity_date_end = params.validity_date_end !== null ? moment(params.validity_date_end).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      }
      params.is_allow_list_all_data = params.isAllowListAllData == true ? 1 : 0;
      params.is_allow_export = params.isAllowExport == true ? 1 : 0;
      params.is_allow_cancel_accounting_integration = params.isAllowCancelAccountingIntegration == true ? 1 : 0;
      params.user_group_id = null;
      params.tmp_session_id = this.$getSessionStorage('tmp_session_id');
      params.date_of_birth = params.date_of_birth !== null ? moment(params.date_of_birth).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      axios.post('/api/Users/CreateUser', params, {'Content-Type': 'application/json'})
      .then((response) => { 
        if(response.data != null && response.data.length == 36){
          this.$refs.closeModal.click();
          this.$parent.getData();
          this.sendVerifyLink();
        }
      })
      .catch(function (error) {
          console.log(error);
          this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
      })
      .finally(function () { 
          setTimeout(() => {
              that.isProgressing = false;
          }, 500);
      });   
    },
    sendVerifyLink() {
      const parameters = {
        session_id: this.$getUser().session_id,
        user_mail: this.form.user_mail,
        account_type: 3
      };  
      const that = this;
      axios.post('/api/Users/SendVerifyLink', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
         if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Verification link has been successfully sent.", 'success');   
         }
      })
      .catch(function () {
         that.$swal("Failed", "An error occurred while sending the email. You can wait for a while to resend or check the mail server settings.", 'error');
      });
    },
    addUserGroup(){
      if (this.user_group_id == '-1' || this.user_group_id == '' || this.user_group_id == null || this.user_group_id == undefined) {
        this.$swal("Failed", "User must be selected..!", 'warning' );
        return;
      }
      const parameters = {
        session_id: this.$getUser().session_id,
        company_id: this.$getSessionStorage("cp_record_id"),
        tmp_session_id: this.$getSessionStorage('tmp_session_id'),
        user_group_id: this.user_group_id,
      };
      axios.post('/api/UserGroup/AddUserGroupTemp', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
          this.groupUserList = response.data;
          this.user_group_id = '-1';
          this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
        }
      });
    },
    deleteUserGroup(){
      this.$swal.fire({
        title: "Are you sure you want to remove this item?",
        confirmButtonColor: "#f1416c",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "DELETE",
        icon: 'info'
      })
      .then((result) => {
        if (result.isConfirmed) {
          const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            user_group_id: this.selectedUserGroupId,
            is_delete_all: 0
          }; 
          axios.post('/api/UserGroup/DeleteUserGroupTemp', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
            this.groupUserList = response.data;
            this.selectedUserId = null;
            this.user_group_id = '-1';
          }) 
          .catch(function (error) {
              console.log(error);
          });
        }
      });
    },
    deleteAllUsersGroup(){ 
      this.$swal.fire({
        title: "Are you sure you want to remove all items?",
        confirmButtonColor: "#f1416c",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "DELETE",
        icon: 'info'
      })
      .then((result) => {
        if (result.isConfirmed) { 
          const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            user_group_id: null,
            is_delete_all: 1,
          }; 
          axios.post('/api/UserGroup/DeleteUserGroupTemp', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
            this.groupUserList = response.data;
            this.selectedUserId = null;
            this.user_group_id = '-1';
          }) 
          .catch(function (error) {
            console.log(error);
          });
        }
      });
    },
    onSelectItem(item) { 
      this.selectedUserGroupId = item.id;
      this.groupUserList.forEach((value) => {
        if (item.id !== value.id) { 
          value.isSelected = false;
        }
      });
    },
    clearForm() {
      this.$removeSessionStorage('tmp_session_id');
      this.form = { 
        account_type: 3,
        permission_type: 1,
        company_account_id: null,
        user_assignment_id: '-1',
        controller_account_id: '-1',
        user_mail: null,
        user_password: null,
        name_surname: null,
        phone: null,
        user_description: null,
        login_method : 1,
        authentication_type: 1,
        validity_type: 1,
        validity_date_start: new Date(),
        validity_date_end: new Date(),
        password_validity: -1,
        is_active: 1,
        isActive: true,
        session_id: null,
        isOwner: false,
        is_owner: 0,
        isChatbiUser: false,
        is_chatbi_user: 0,
        gender: '-1',
        date_of_birth: null
      };
    }
  }
}; 
</script>